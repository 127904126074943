<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the pH of a solution formed by mixing 250.0&nbsp;mL of 0.350&nbsp;M aqueous
        ammonia with 200.0&nbsp;mL of 0.250&nbsp;M ammonium chloride. Note:
        <stemble-latex content="$\text{K}_\text{a}(\ce{NH4+})=5.6\times 10^{-10}.$" />
      </p>

      <calculation-input
        v-model="inputs.pH"
        class="mb-0"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'Question541',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        pH: null,
      },
    };
  },
};
</script>
